export enum IncomingMessageTypes {
  NEW_MESSAGE = 'NEW_MESSAGE',
  MESSAGES_COUNT = 'MESSAGES_COUNT',
  ALL_MESSAGES = 'ALL_MESSAGES',
  ALL_GAMES = 'ALL_GAMES',
  GAME_UPDATED = 'GAME_UPDATED',
  DUPLICATED_WORDS = 'DUPLICATED_WORDS',
  NO_DUPLICATIONS = 'NO_DUPLICATIONS',
  DELETE_GAME_IN_LOBBY = 'DELETE_GAME_IN_LOBBY',
  UPDATED_GAME_IN_LOBBY = 'UPDATED_GAME_IN_LOBBY',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  ERROR = 'ERROR',
}
